<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
             <a-table :pagination='false' :loading='listLoading' bordered :columns='columns' :data-source='data'  :row-selection="rowSelection">
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item >
                  <a v-has:appletCodeDetail="()=>$router.push('/appletCode/appletDetail/index')" href="javascript:;">详情</a>
                </a-menu-item>
                 <a-menu-item >
                  <a v-has:agentDel="()=>appletDel(record)" href="javascript:;">发布</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
        <!-- </a-tab-pane>
        <a-tab-pane key="2" tab="已发布">
             <a-table :pagination='false' :loading='listLoading'  rowKey='id' :columns='columns' :data-source='data'  >
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item >
                  <a v-has:appletCodeDetail="()=>$router.push('/appletCode/appletDetail/index')" href="javascript:;">详情</a>
                </a-menu-item>
                 <a-menu-item >
                  <a v-has:appletCodePut="()=>appletCodePut(record)" href="javascript:;">发布</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
        </a-tab-pane>
      </a-tabs> -->
      <a-button type="link"  v-has:appletReleaseCode="()=>appletReleaseCode(record)">
        批量发布
      </a-button>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
   <a-modal v-model:visible="visible" title="发布" @ok="handleOk" centered :confirm-loading="confirmLoading" destroyOnClose :width="1000"  :height="400">
   <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns1' :data-source='data' >
    </a-table>
   <p class="mb10" style="color:#f04635">是否要确定批量重新审核失败的小程序么？</p>
  </a-modal>
</template>
<script>
import { DownOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, inject, reactive, toRefs } from "vue";
import { message } from "ant-design-vue";
import { getRouter } from "@/utils/app";
import { setPageState, getPageState } from "@/utils/pageState";
import { miniCodebatchIndex } from "@/api/appletCode";
import { $iscode } from "@/utils/app";
// 初始化默认筛选项数值
let defSearch = {
  page: 1,
  limit: 10,
  is_release:0,
  audit_status:0
};
export default {
  components: { DownOutlined },
  setup(props, ctx) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      listLoading: false,
      total: 1,
      active:1,
      visible:false,
      activeKey:0
    });
    let columns = [
      {
        title: "版本号",
        dataIndex: "user_version",
        key: "user_version",
        width: 150,
      },
      {
        title: "模板名称",
        dataIndex: "developer",
        key: "developer",
      },
           {
        title: "品牌商名称",
        dataIndex: "brand_name",
        key: "brand_name",
      },
        {
        title: "发布状态",
        dataIndex: "status_text",
        key: "status_text",
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      {
        title: "操作",
        key: "action",
        fixed: "right",
        width: 100,
        slots: { customRender: "action" },
      },
    ];
    const $router = inject("$router");
    const $Modal = inject("$Modal");
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, limit) => {
      search.value.page = 1;
      search.value.limit = limit;
      initData(search.value);
    };

    //列表
    const initData = async (values) => {
      state.listLoading = true;
        let res =await miniCodebatchIndex(values).then((res) => res.data);;
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        }
    
    };
    onMounted(() => {
      initData(search.value);
    });
        const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
      },
    };
    const callback=(e)=>{
      search.value.audit_status=e
       initData(search.value);
    }
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      rowSelection,
      callback
    };
  },
};
</script>